/* This is used only for this component styles
 * @import any common tailwind grid styles if you want to use its variables, mixins, etc
 * keep @drive namespace
 * dummy styles - purely for example demonstration purposes only
 */

.drive-page-wrapper {
  &__container {
    content: '';
    width: inherit;
  }
}

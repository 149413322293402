@use '/styles/mixin/fonts.module.scss' as *;
.drive-navbar__items {
  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply flex-nowrap;
    @apply p-4;
    @screen xl {
      @apply mx-2;
      @apply p-0;
      @apply mt-[0.563rem];
    }
  }

  &__link {
    @apply text-black;
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @screen xl {
      @include font-subtext('desktop', 'medium');
      &:hover {
        @apply text-white;
      }
      @apply text-white;
      @apply h-8;
    }

    &:hover {
      @apply text-blue-normal;
    }
  }

  &__icon-wrapper {
    @apply text-2xl;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply cursor-pointer;
    @screen xl {
      @apply ml-1;
      @apply -mt-2;
    }
  }

  &__expand-more-icon {
    @apply h-6;
    @apply fill-current;
    @screen xl {
      @apply flex;
    }
  }
  &__expand-less-icon {
    @apply h-6;
    @apply fill-current;
    @screen xl {
      @apply hidden;
    }
  }
  &__container {
    @apply max-w-[49rem];
    @apply h-full;
    @screen md {
      @apply align-middle;
    }
    @screen xl {
      @apply flex-grow;
      @apply flex;
    }
  }
  &__menus {
    @apply flex;
    @apply flex-col;
    max-height: calc(100vh - 168px);
    @apply overflow-y-scroll;
    @screen xl {
      @apply overflow-y-visible;
      @apply max-h-full;
      @apply flex-row;
      @apply items-center;
      @apply justify-around;
      @apply flex-1;
    }
  }

  &__menu-wrapper {
    @apply border-b;

    &:last {
      @apply m-0;
      @apply mr-0;
    }

    @screen xl {
      @apply border-0;
      @apply h-20;
    }
  }
  &__menu {
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;

    @screen xl {
      @apply h-20;
      @apply mr-3;
    }
  }

  &__subscribe-wrapper {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply py-4;
    @screen xl {
      @apply pt-0;
    }
  }
  &__subscribe {
    @apply flex;
    @apply justify-center;
    @apply my-6;
    @screen xl {
      @apply hidden;
    }
  }
  &__social {
    @apply mb-4;
    @apply mt-0;
    @screen xl {
      @apply hidden;
    }
  }
  &__mobile-show-all {
    [class^='subitems_drive-navbar__subitems__container'] {
      @apply pb-0;
    }

    [class^='subitems_drive-navbar__subitems__cols-wrapper'] {
      @apply flex;
      @apply py-0;
      background: inherit;
      @screen xl {
        @apply pl-4;
        @apply pr-0;
        @apply pt-6;
        @apply pb-0;
      }

      [class^='subitems_drive-navbar__subitems__link'] {
        @apply ml-2;
        @apply mb-4;
        @apply mt-0;
        @screen xl {
          @apply m-0;
        }
      }
    }

    [class^='subitems_drive-navbar__subitems__col-heading'] {
      @apply hidden;
      @screen xl {
        @apply flex;
      }
    }
  }
}

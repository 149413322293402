@use '/styles/mixin/fonts.module.scss' as *;
.d-athena-search-button {
  @apply w-full;
  @apply min-w-[182px];
  &__button {
    @apply w-[182px];
    @apply overflow-hidden;
    &__label {
      @apply w-full;
      @apply h-8;

      @screen md {
        @apply h-[2.875rem];
      }
      @apply flex;
      @apply justify-end;
      @apply items-center;
      @apply rounded-lg;

      @apply text-white;
      @include font-subtext('desktop', 'medium');
      @include font-text('desktop', 'small');
      @apply pr-2;
      @apply pl-[14px];
      @apply rounded-[100px];
      &--modal-closed {
        @apply text-gray-300;
        @apply gap-3;
        @apply bg-[#323232];
        @apply text-opacity-70;
      }
    }
    &:hover {
      @apply cursor-pointer;
      @apply opacity-80;
      @apply transition-opacity;
    }
  }
  &__text-group {
    @apply flex-grow;
    @apply relative;
    @apply flex;
    @apply items-center;
    @apply h-5;
    @apply my-2;
    @apply overflow-hidden;
    @apply min-w-[125px];
    &__text {
      @apply left-0;
      @apply absolute;
      @apply top-full;
      --items-count: 6;
      --single-animation-duration: 2.8s;
      animation: scroll-items calc(var(--items-count) * var(--single-animation-duration))
        ease-in-out infinite;

      @keyframes scroll-items {
        0% {
          @apply top-full;
        }
        6.5% {
          @apply top-0;
        }
        17.5% {
          @apply top-0;
        }
        22% {
          @apply -top-full;
        }
        100% {
          @apply -top-full;
        }
      }
      --first-delay: -0.4s; //skip to idle animation
      &:nth-child(1) {
        animation-delay: var(--first-delay);
      }
      &:nth-child(2) {
        animation-delay: calc(var(--single-animation-duration) + var(--first-delay));
      }
      &:nth-child(3) {
        animation-delay: calc(var(--single-animation-duration) * 2 + var(--first-delay));
      }
      &:nth-child(4) {
        animation-delay: calc(var(--single-animation-duration) * 3 + var(--first-delay));
      }
      &:nth-child(5) {
        animation-delay: calc(var(--single-animation-duration) * 4 + var(--first-delay));
      }
      &:nth-child(6) {
        animation-delay: calc(var(--single-animation-duration) * 5 + var(--first-delay));
      }
    }
  }
  :global(.drive-icon-CloseIcon) {
    @apply ml-2;
  }
}

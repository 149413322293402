@use '/styles/mixin/fonts.module.scss' as *;

.drive-notification-banner {
  @apply w-full;
  @apply text-center;
  @apply z-[100];
  @apply fixed;
  @apply text-black;
  @include font-text('mobile', 'medium');
  @screen lg {
    @include font-text('desktop', 'large');
  }
  @apply invisible;
  @apply top-0;
  @apply overflow-hidden;
  @apply transition-opacity;
  @apply duration-300;
  @apply opacity-0;
  @apply left-1/2;
  @apply -translate-x-1/2;
  &--show {
    @apply visible;
    @apply opacity-100;
  }
  &--mainnav-sticky-PTO {
    @apply max-w-[1280px];
  }

  $mainnav-height: 64px;
  $subnav-height: 52px;
  &--mainnav-sticky,
  &--mainnav-sticky-PTO {
    top: $mainnav-height;
  }
  &--subnav-sticky {
    top: $subnav-height;
  }
  &--stacked-sticky {
    top: calc($mainnav-height + $subnav-height);
  }

  @screen xl {
    $subnav-height: 64px;
    &--subnav-sticky {
      top: $subnav-height;
    }
    &--stacked-sticky {
      top: calc($mainnav-height + $subnav-height);
    }
  }

  @screen lmd {
    $mainnav-height: 80px;
    &--mainnav-sticky {
      top: $mainnav-height;
    }
    &--stacked-sticky {
      top: calc($mainnav-height + $subnav-height);
    }
  }

  &__content {
    @apply shadow-md;
    @apply p-2.5;
    @apply transition-transform;
    @apply duration-300;
    @apply bg-gray-200;
    @apply -translate-y-full;
    @apply m-0;
    &--show {
      @apply translate-y-0;
    }
  }
}

/* This is used only for this component styles
 * @import any common tailwind grid styles if you want to use its variables, mixins, etc
 * keep @drive namespace
 * dummy styles - purely for example demonstration purposes only
 */

@use '../../styles/variables.scss' as *;
//header nav
.drive-nav-wrapper {
  &__main-nav {
    @apply h-full;
    @apply flex;
    @apply mx-auto;
    @apply select-none;
    @apply max-w-[90rem];
    @screen xl {
      @apply items-start;
      @apply h-20;
      @apply pb-0;
      @apply pt-4;
      @apply px-6;
      .navbar-item:hover .navbar-children {
        @apply flex;
      }
    }
  }

  &__hamburger-btn {
    @apply w-6;
    @screen xl {
      @apply mt-[0.625rem];
    }
  }

  &__side-wrapper {
    input[id='side-menu-checkbox'] {
      @apply fixed;
      @apply invisible;
      @apply -top-full;
      @apply select-none;
    }

    //should be declared before &__side-content--show
    &__side-content {
      @apply invisible;
      z-index: $z-index-menu;
      @apply max-h-0;
      @apply overflow-hidden;
      @apply fixed;
      @apply left-0;
      @apply w-full-screen;
      @screen md {
        @apply w-[16.25rem];
      }
    }

    //toggled by checkbox when js disabled, input:checked selector fails when page is navigated from browser back button
    input[id='side-menu-checkbox']:checked + &__side-content,
    &__side-content--show {
      @apply max-h-screen;
      @apply visible;
    }
  }

  //this is just a wrapper of header menu component, matching its height
  &__header-menu {
    height: $header-height-mobile;
    @screen xl {
      height: $header-height-desktop;
    }
  }

  &__sticky-gam-menu {
    @apply bg-black;
    @media (min-width: 1280px) {
      @apply sticky;
      z-index: $z-index-header;
      @apply w-[100%];
      @apply top-0;
    }
  }

  &__hamburger-btn-wrapper {
    @apply flex;
    @apply items-center;

    @apply p-3;

    @screen md {
      @apply flex-none;
      @apply pr-0;
      @apply pl-30px;
      @apply mr-0;
    }
    @screen xl {
      @apply mr-8;
      @apply px-0;
      @apply pt-1;
    }
    @screen l {
      @apply mr-[44px];
    }
  }

  &__link-wrapper {
    @apply flex;
    @apply items-center;
    @apply shrink-0;
    @apply flex-grow;
    @screen md {
      @apply justify-center;
    }
    @screen xl {
      @apply grow-0;
      @apply mt-2;
    }
    &__logo {
      @apply w-20;
      @screen md {
        @apply w-21;
      }
      @screen xl {
        @apply w-[104px];
        @apply mt-[5px];
      }
    }
  }

  &__items-wrapper {
    @apply hidden;
    @screen xl {
      @apply static;
      @apply z-50;

      @apply pb-4;
      @apply px-6;
      @apply mt-0;

      @apply flex;
      @apply flex-1;
      @apply justify-center;
      @apply items-center;

      @apply w-full;
      @apply h-16;
      @apply bg-black;
    }
  }

  &__ai-chat-wrapper {
    @apply flex;
    @apply shrink-0;
    @apply w-[2.813rem]; //125px
    @apply -mr-4;
    @apply items-center;
    @screen md {
      @apply w-[7.813rem]; //125px
      @apply ml-6;
      @apply mr-5;
      @apply ml-0;
    }
    @screen xl {
      @apply ml-6;
      @apply pt-0.5;
      @apply w-35;
    }
  }

  &__athena-button-wrapper {
    @apply flex;
    @apply shrink-0;
    @apply min-w-[182px];
    @apply mr-5;
    @apply items-center;
    @screen xl {
      @apply pt-0.5;
    }
  }

  &__icon-btn {
    @apply fill-current;
    @apply h-6;
    @apply w-6;
    @apply max-h-6;
    @apply max-w-6;
    @apply overflow-hidden;
    @apply cursor-pointer;
  }
  &__close-wrapper {
    @apply overflow-hidden;
    @apply relative;
  }

  &__container {
    @apply overflow-auto;

    @screen xl {
      @apply overflow-hidden;
    }
  }
}

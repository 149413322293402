@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;
@use '../../../styles/variables.scss' as *;

$sell-by-call-btn-height: 3rem;

.drive-side-menu-wrapper {
  @apply box-border;

  @apply h-screen;
  @apply overflow-hidden;
  @apply flex;
  @apply w-full;
  @apply bg-black;
  top: $header-height-mobile;

  @screen md {
    @apply w-[16.25rem];
  }
  @screen xl {
    top: $header-height-desktop;
  }

  .drive-side-menu-content {
    @apply bg-black;
    @apply flex;
    @apply flex-col;
    @apply flex-grow;
    @apply w-full;
  }

  // controls the height of the container
  :global([class^='gridWrapper_d-padding__all']) {
    @apply overflow-hidden;
    @apply flex;
    @apply w-full;
    @apply mt-0;
    @apply pt-0;

    height: calc(100vh - #{$header-height-mobile});

    @screen md {
      @apply px-30px;
    }

    @screen xl {
      height: calc(100vh - #{$header-height-desktop});
    }
  }
}

.drive-side-menu {
  @include hide-scroll;

  @apply text-white;
  @apply flex;
  @apply flex-col;
  @apply flex-1;
  @apply overflow-y-hidden;

  @screen md {
    @apply overflow-y-scroll;
    @apply px-0;
  }

  &__items {
    @include hide-scroll;
    @apply overflow-y-scroll;
    @apply flex-grow;

    @screen md {
      max-height: unset;
      @apply overflow-y-visible;
    }
  }

  &__sell-my-car-btn {
    @apply flex;
    @apply shrink-0;
    @apply w-full;
    height: $sell-by-call-btn-height;
    @apply mt-6;
    @screen xl {
      @apply hidden;
    }
  }

  &__item {
    @apply flex;
    @apply flex-1;
    @apply flex-col;
    @apply py-1;
    user-select: none;
    a:hover {
      @apply text-blue-normal;
    }
    &:first-child {
      @apply pt-[0.875rem];
    }

    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'medium');
    }

    &--parent {
      @include font-heading('mobile', 'h4');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }

      @apply py-11px;
      border-bottom: 0.5px solid #ffffff;
    }

    &__header {
      @apply flex;
      @apply flex-1;
      @apply justify-between;
      @apply items-center;
      font-size: bold;
    }

    &__link {
      @apply flex;
      @apply flex-grow;

      &__text {
        @apply inline;
        @apply text-white;
        @apply cursor-pointer;
      }
    }

    &__submenu-toggle {
      @apply text-white;
      @apply cursor-pointer;
    }

    // transition/animation height will not work since no specific value.
    @mixin submenus-opened {
      @apply max-h-full;
      animation: appear forwards 0.25s;
      @keyframes appear {
        from {
          opacity: 0.5;
        }
        to {
          opacity: 1;
        }
      }
    }

    &__submenus {
      @apply flex;
      @apply flex-col;
      @apply pl-6;
      @apply h-full;
      @apply max-h-0;
      @apply overflow-hidden;
      @apply opacity-0;
      &--opened {
        @include submenus-opened;
      }
    }

    [name='side-menu-item'] {
      @apply fixed;
      @apply invisible;
      @apply -top-full;
      @apply select-none;
    }

    [name='side-menu-item']:checked + &__submenus {
      @include submenus-opened;
    }
  }
}

$sizes: (
  gridXxs,
  gridXs,
  gridS,
  grid2s,
  gridM,
  grid2m,
  gridL,
  grid2l,
  grid3l,
  gridXl,
  grid2xl,
  grid3xl
);
@mixin gridSize($postion: 'all', $size: 'gridS', $type: 'padding') {
  $pos: ''; /* by default is all which relates to tailwid as without any prefix such as x, t, b, y */
  $sep: '-'; /* tailwind separator */
  $gridType: 'p'; /* tailwind type 'p' for padding and 'm' for margin */

  @if $type == 'margin' {
    $gridType: 'm';
  }

  @if $postion == 'bottom' {
    $pos: 'b';
  } @else if $postion == 'left' {
    $pos: 'l';
  } @else if $postion == 'right' {
    $pos: 'r';
  } @else if $postion == 'top-bottom' {
    $pos: 'y';
  } @else if $postion == 'left-right' {
    $pos: 'x';
  } @else if $postion == 'top' {
    $pos: 't';
  }

  @apply #{$gridType + $pos + $sep + $size};
}

@mixin gridStyles($position, $type) {
  @each $size in $sizes {
    &__#{$size} {
      @include gridSize($position, $size, $type);
    }
  }
}

.drive-icon {
  @apply fill-current;
}

.drive-icon-CommentIcon {
  @apply w-5;
  @apply h-5;
}

.drive-icon-ExpandMoreIcon {
  @apply h-18px;
}

.drive-icon-DcotyWinnerIcon {
  @apply h-18px;
}

.drive-icon-DcotyFinalistIcon {
  @apply h-18px;
}

.drive-icon-TopFiveIcon {
  @apply max-w-5;
  @apply h-18px;
  @apply w-18px;
}

.drive-icon-ChevronUp {
  @apply h-3;
}

.drive-icon-ChevronDown {
  @apply h-3;
}

.drive-icon-ArrowForwardIcon {
  @apply h-4;
}

.drive-icon-ArrowBackIcon {
  @apply h-4;
}

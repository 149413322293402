@use '../../../styles/variables.scss' as *;

.drive-navbar__header-menu {
  @apply bg-black;
  //preserve height here as header menu content can be posiiton fixed
  height: $header-height-mobile;
  @screen xl {
    height: $header-height-desktop;
  }

  &--mobile-sticky {
    @apply z-60;
    @apply fixed;
    @apply w-full;
    @apply top-0;
    @screen md {
      @apply static;
    }
  }
  &--sticky {
    @apply z-60;
    @apply fixed;
    @apply w-full;
    @apply top-0;
  }
}
